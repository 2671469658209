/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

//Default variables
$primaryBlueColor: #1b3d6a;
$secondaryBlueColor: #2b78a7;
$yellowColor: #fff041;
// $primaryAlternativeColor: #8FAAC5;
$secondaryAlternativeColor: #60a9d6;
$backgroundColor: #d2dde8;
$grayColor: #3b3b3b;
$grayGradientColor: #515156;
$grayLightColor: #898989;
$primaryColor: #1b3d6a;
$primaryAlternativeColor: #8faac5;
$lightPrimaryAlternativeColor: #d2dde8;
$secondaryColor: #2b78a7;
$secondaryAlternativeColor: #60a9d6;
$whiteColor: #ffffff;
$blackColor: #000000;
$yellowColor: #fff041;
$grayColor: #3b3b3b;
$lightGrayColor: #898989;
$grayGradient: linear-gradient(90deg, #29292b 0%, #515156 100%);
$textColor: var($grayColor);
$headlineColor: var($primaryColor);
$linkColor: var($primaryColor);
$headerColor: rgba(27, 61, 106, 0.5);
$headerHeight: 174px;
$maxContentWidth: 1600px;
$maxContainerWidth: 2560px;
$maxNavigationWidth: 440px;
$contentPadding: 48px;
$mainFontLight: DINW05-Light, Verdana-Fallback, sans-serif;
$mainFont: DINW05-Medium, Verdana-Fallback, sans-serif;
$mainFontBold: DINW05-Bold, Verdana-Fallback, sans-serif;
$quoteFont: SabonLTW05-Italic, Georgia, serif;

// refactoring colour patterns
$primary-500: #1B3D6A; // header font color and active btn background color
$primary-200: #96A6BA; // switch lamg
$grey-900: #191919;
$grey-500: #3B3B3B; // sub heading
$grey-400: #626262; // field label and normal label
$grey-200: #A5A5A5; // field placeholder and btn label
$grey-50: #EBEBEB; // disable btn background
$tertiary-500: #2B78A7; // footer content color
$mainFontstyle: DIN OT;

$arrowWhite: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA4NCA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYwLjcxNTkgMy42OTA4N0M2MC4wMjIgMi45ODg1NSA1OS44MjkzIDIuNjU2OSA1OS43MTM2IDIuMjA4MTlDNTkuNTc4NyAxLjY0MjQzIDU5Ljc3MTUgMS4wMzc2NSA2MC4yMzQxIDAuNTY5NDRDNjAuNjk2NiAwLjEwMTIyNiA2MS4yOTQyIC0wLjA5Mzg2MzYgNjEuODUzMSAwLjA0MjY5ODlDNjIuMjc3MiAwLjE1OTc1MyA2Mi42MDQ5IDAuMzM1MzMzIDYzLjMxOCAxLjA1NzE2TDg0IDIxLjk5MDJMNjMuMjk4OCA0Mi45NDI4QzYyLjYwNDkgNDMuNjQ1MiA2Mi4yNzcyIDQzLjg0MDIgNjEuODMzOSA0My45NTczQzYxLjI3NDkgNDQuMDkzOSA2MC42Nzc0IDQzLjg5ODggNjAuMjE0OCA0My40MzA2QzU5Ljc1MjIgNDIuOTYyMyA1OS41NTk0IDQyLjM1NzYgNTkuNjk0NCA0MS43OTE4QzU5LjgxIDQxLjM2MjYgNTkuOTgzNSA0MS4wMzEgNjAuNjk2NiA0MC4zMDkxTDc3LjA2MSAyMy43NDZIMi40MDkzNkMxLjU0MTk5IDIzLjc0NiAxLjEzNzIyIDIzLjY4NzUgMC43NzA5OTYgMjMuNDUzNEMwLjI2OTg0OSAyMy4xMjE4IDAgMjIuNjM0IDAgMjEuOTkwMkMwIDIxLjMyNjkgMC4yNjk4NDkgMjAuODM5MiAwLjc1MTcyMSAyMC41MDc2QzEuMTM3MjIgMjAuMjU0IDEuNTQxOTkgMjAuMjE0OSAyLjM5MDA5IDIwLjIxNDlINzcuMDAzMkw2MC43MTU5IDMuNjkwODdaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
$arrowPrimary: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODYiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCA4NiA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYyLjE2MTUgMy42OTA4N0M2MS40NTExIDIuOTg4NTUgNjEuMjUzOCAyLjY1NjkgNjEuMTM1NCAyLjIwODE5QzYwLjk5NzIgMS42NDI0MyA2MS4xOTQ2IDEuMDM3NjUgNjEuNjY4MiAwLjU2OTQ0QzYyLjE0MTggMC4xMDEyMjYgNjIuNzUzNiAtMC4wOTM4NjM2IDYzLjMyNTggMC4wNDI2OTg5QzYzLjc2IDAuMTU5NzUzIDY0LjA5NTUgMC4zMzUzMzMgNjQuODI1NiAxLjA1NzE2TDg2IDIxLjk5MDJMNjQuODA1OSA0Mi45NDI4QzY0LjA5NTUgNDMuNjQ1MiA2My43NiA0My44NDAyIDYzLjMwNjEgNDMuOTU3M0M2Mi43MzM4IDQ0LjA5MzkgNjIuMTIyMSA0My44OTg4IDYxLjY0ODUgNDMuNDMwNkM2MS4xNzQ5IDQyLjk2MjMgNjAuOTc3NSA0Mi4zNTc2IDYxLjExNTYgNDEuNzkxOEM2MS4yMzQxIDQxLjM2MjYgNjEuNDExNyA0MS4wMzEgNjIuMTQxOCA0MC4zMDkxTDc4Ljg5NTggMjMuNzQ2SDIuNDY2NzNDMS41Nzg3MSAyMy43NDYgMS4xNjQzIDIzLjY4NzUgMC43ODkzNTMgMjMuNDUzNEMwLjI3NjI3NCAyMy4xMjE4IDAgMjIuNjM0IDAgMjEuOTkwMkMwIDIxLjMyNjkgMC4yNzYyNzMgMjAuODM5MiAwLjc2OTYxOSAyMC41MDc2QzEuMTY0MyAyMC4yNTQgMS41Nzg3MSAyMC4yMTQ5IDIuNDQ2OTkgMjAuMjE0OUg3OC44MzY2TDYyLjE2MTUgMy42OTA4N1oiIGZpbGw9IiMxQjNENkEiLz4KPC9zdmc+Cg==);
$arrowSecondary: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCA0MCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yOC45MTIzIDEuNzYxNTVDMjguNTgxOSAxLjQyNjM1IDI4LjQ5MDEgMS4yNjgwNiAyOC40MzUxIDEuMDUzOTFDMjguMzcwOCAwLjc4Mzg4OCAyOC40NjI2IDAuNDk1MjQ0IDI4LjY4MjkgMC4yNzE3NzhDMjguOTAzMiAwLjA0ODMxMjMgMjkuMTg3NyAtMC4wNDQ3OTg1IDI5LjQ1MzkgMC4wMjAzNzlDMjkuNjU1OCAwLjA3NjI0NTUgMjkuODExOCAwLjE2MDA0NSAzMC4xNTE0IDAuNTA0NTU1TDQwIDEwLjQ5NTNMMzAuMTQyMyAyMC40OTU0QzI5LjgxMTggMjAuODMwNiAyOS42NTU4IDIwLjkyMzggMjkuNDQ0NyAyMC45Nzk2QzI5LjE3ODUgMjEuMDQ0OCAyOC44OTQgMjAuOTUxNyAyOC42NzM3IDIwLjcyODJDMjguNDUzNCAyMC41MDQ4IDI4LjM2MTYgMjAuMjE2MSAyOC40MjU5IDE5Ljk0NjFDMjguNDgxIDE5Ljc0MTIgMjguNTYzNiAxOS41ODMgMjguOTAzMiAxOS4yMzg0TDM2LjY5NTcgMTEuMzMzM0gxLjE0NzMyQzAuNzM0MjgyIDExLjMzMzMgMC41NDE1MzMgMTEuMzA1NCAwLjM2NzE0MSAxMS4xOTM3QzAuMTI4NDk5IDExLjAzNTQgMCAxMC44MDI2IDAgMTAuNDk1M0MwIDEwLjE3ODggMC4xMjg0OTkgOS45NDU5OSAwLjM1Nzk2MiA5Ljc4NzdDMC41NDE1MzMgOS42NjY2NiAwLjczNDI4MiA5LjY0ODA0IDEuMTM4MTQgOS42NDgwNEgzNi42NjgyTDI4LjkxMjMgMS43NjE1NVoiIGZpbGw9IiMyQjc4QTciLz4NCjwvc3ZnPg==);
$checkIcon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjA3MzUgOS4yMDdMMTMuMiAyMC4wODA1TDcuMjc2NSAxNC4xNzM1TDQuOTUgMTYuNUwxMy4yIDI0Ljc1TDI2LjQgMTEuNTVMMjQuMDczNSA5LjIwN1pNMTYuNSAwQzcuMzkyIDAgMCA3LjM5MiAwIDE2LjVDMCAyNS42MDggNy4zOTIgMzMgMTYuNSAzM0MyNS42MDggMzMgMzMgMjUuNjA4IDMzIDE2LjVDMzMgNy4zOTIgMjUuNjA4IDAgMTYuNSAwWk0xNi41IDI5LjdDOS4yMDcgMjkuNyAzLjMgMjMuNzkzIDMuMyAxNi41QzMuMyA5LjIwNyA5LjIwNyAzLjMgMTYuNSAzLjNDMjMuNzkzIDMuMyAyOS43IDkuMjA3IDI5LjcgMTYuNUMyOS43IDIzLjc5MyAyMy43OTMgMjkuNyAxNi41IDI5LjdaIiBmaWxsPSIjNjBBOUQ2Ii8+Cjwvc3ZnPgo=);
$defaultTransition: 240ms ease-in-out;
//
@font-face {
  font-family: 'DIN OT';
  src: url('assets/fonts/DINOT-Medium.ttf');
}
html {
  color: var($secondaryBlueColor);
  overflow-x: hidden;
  font-family: var($mainFontstyle);
  background-color: var($backgroundColor, #fff);
  position: relative;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'DIN OT' !important;
  font-style: normal;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $grey-500;
  background: #fff;
}

.basic-theme{
  width: 100%;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-500;
}

.ft-16 {
  font-size: 16px !important;
}

.ft-18 {
  font-size: 18px !important;
}

.ft-20 {
  font-size: 20px !important;
}

.ft-24 {
  font-size: 24px !important;
}

.errMsg {
  color: #D11538;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.ft-14 {
  font-size: 14px;
}

.cursor {
  cursor: pointer;
  float: left;
}

.ft-12 {
  font-size: 12px;
}

.login-content {
  height: 100vh;

  .logo {
    width: 200px;
    height: 200px;
    margin: auto;
  }

  .left-side {
    background: #d2dde8;
  }
}

.arrow-right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.text-para {
  // width: 80%;
  // margin: 50px;
  // width: 100%;
  width: 85%;
  margin: 0px auto;
  padding-top: 30px;

}

.middle-image {
  width: 100px;
  height: 100px;
}

.border-bottom {
  border-bottom: 1px solid #ced4da !important;
}

/* icon-circle */

.icon-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #d2dde8;
  display: flex;
  margin-right: 16px;
}

.img-icon {
  width: 20px;
  height: 20px;
  margin: auto;
}

.active-icon {
  background-color: #1b3d6a;
}

.active-img {
  background-color: #9baabe;
  border-radius: 50%;
}

/* button */

.btn-common {
  color: white;
  background: #2b78a7;
}

.lock-content {
  border: 1px solid #dedede;
  width: 90px;
  border-radius: 50%;
  height: 90px;
  display: flex;
  margin: auto;
}

.img-lock {
  width: 50px;
  height: 50px;
  margin: auto;
}

.footer {
  // position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  padding: 14px;

  @media (min-width: 376px) {
    // padding: 0px;
    // position: absolute;

  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 0px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0px;
  }
}

.header-label {
  width: 100%;
  // height: 32px;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $primary-500;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 20px;
  // @media (min-width: 481px) and (max-width: 767px) {
  //   width: 100%;
  // }

  // @media (min-width: 320px) and (max-width: 1024px) {
  //   width: 100%;
  // }
}

.secondary-header-label {
  // margin: 12px 0px;
  width: 100%;
  // height: 96px;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-500;
  margin: 28px 0px;
  // @media (min-width: 481px) and (max-width: 767px) {
  //   width: 100%;
  // }

  // @media (min-width: 320px) and (max-width: 1024px) {
  //   width: 100%;
  // }
}

.secondary-part-header-label {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $primary-500;
}

.secondary-header-error-label {
  font-size: 14px;
  margin: 12px 0px;
  color: #D11538;
}

.btn-primary {
  color: #1b3d6a;
  background-color: #d2dde8 !important;
  border-color: #d2dde8 !important;
  font-size: 14px;
}

.btn-primary.focus,
.btn-primary:focus,
.btn.active,
.btn:active {
  box-shadow: none !important;
}

.btn-primary:focus-visible,
.btn-primary:focus:not(:focus-visible) {
  outline: none !important;
  border: none !important;
}

input {
  font-size: 16px !important;
}

.fixed-footer {
  width: 100%;
  position: fixed;
  bottom: 0% !important;
  background: white;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 4.5rem 1rem !important;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 1.5rem 1rem !important;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    padding: 1.5rem 1rem !important;
  }

  @media (min-width: 1281px) {
    padding: 1.5rem 1rem !important;
  }
}

@media screen and (max-width: 568px) {
  .login-content {
    // flex-direction: column;

    // .right-side {
    //   height: 100vh;
    // }
    .left-side {
      height: 100px;
    }

    .navbar-nav>li>a {
      line-height: 0px;
    }

    .navbar {
      margin-top: 0px;
      padding: 0px !important;
    }
  }

  .text-para {
    width: 85%;
  }

  input {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .text-para {
    width: 85%;
  }

  .navbar {
    margin-top: 0px;
    padding: 0px !important;
  }

  input {
    font-size: 14px !important;
  }
}

.form-group {
  margin-bottom: 4.5rem;

  input {
    height: 38px;
  }
}

.form-control {
  // border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid #A5A5A5;
  border-radius: 4px !important;

}

.error-form-control {
  border: 2px solid #E6173E !important;
}

.header {
  background: #EEF2F6;
  padding: 14px;
}

.logo {
  width: 155px;
  position: absolute;
  top: 31px;
}

.header {
  color: #3A5177;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.card-content {
  padding-top: 27px;
  padding-bottom: 4%;
  height: 100%;
}

.text-title {
  padding-bottom: 21px;
}

@media screen and (max-width:568px) {
  .card-content {
    width: 98%;
    padding-top: 27px;
    padding-bottom: 1%;
  }
}

@media screen and (max-width:320px) {
  .card-content {
    width: 98%;
    padding-top: 27px;
    padding-bottom: 1%;
  }
}

.consent {
  display: grid;
  grid-template-columns: 39% 1rem 57%;
  height: fit-content;
  align-items: center;
}

.first-consent {
  margin-top: 2rem;
  margin-bottom: 0vmin !important;
}

.consent-text {
  text-align: left;
  margin-bottom: 0vmin !important;
  padding-left: 1rem;
}

.consent-check {
  width: 1rem;
}

@media screen and (max-width: 1200px) {
  .input-fields-container {
    display: block;
  }

  .empty-cont {
    display: none;
  }

  .consent {
    display: grid;
    grid-template-columns: 4% auto;
  }
}

#submit-container {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  float: right;
}

.submit-btn {
  border: 1px solid $primaryAlternativeColor;
  border-radius: 8px !important;
  background-color: $lightPrimaryAlternativeColor;
  outline: none;
  padding: 0.5rem 3rem;
}

.submit-btn:disabled {
  cursor: not-allowed;
}

.form-container {
  font-size: 14px !important;
  display: grid;
  grid-template-rows: repeat(4, auto);
  height: fit-content;
}

.input-fields-container {
  display: grid;
  grid-template-columns: 38% auto;
  align-items: center;
  margin-top: 0.625rem;
}

.input-field {
  border-radius: 8px !important;
  border: 1px solid #8faac5;
  padding: 0.375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  outline: 0px;
  width: 100%;
}

.form-select {
  font-size: 14px;
}

.submit-btn {
  font-size: 1rem !important;
  padding: 0 3rem;
}

.submit-btn:disabled {
  cursor: not-allowed;
}

#submit-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 3vmin;
  width: 100%;
}

input[type="submit"] {
  border: 1px solid #8faac5;
  border-radius: 8px !important;
  background-color: #d2dde8;
}

.error-container {
  margin-top: 4rem;
  display: grid;
  width: fit-content;
  grid-template-rows: auto auto;
}

.back-btn {
  color: #495057;
  font-size: 14px;
  margin-top: 2vmin;
  cursor: pointer;
  border-radius: 8px !important;
  border: 1px solid #8faac5;
  background-color: #d2dde8;
  width: fit-content;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 1200px) {
  .input-fields-container {
    display: block;
  }
}

.reg-image {
  width: 100%;
  height: auto;
}

.tip-container {
  color: $primaryBlueColor;
  background-color: $backgroundColor;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.app_banner {
  background-image: url("../src/assets/img/banner.png");
  background-size: cover;
  margin-top: 75px;
  position: initial;

  @media (min-width: 481px) and (max-width: 767px) {
    position: relative;
    background-image: none;
    margin: 0px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    background-image: none;
    margin: 0px;

  }
}

.right-side {
  float: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  height: 92vh;
  overflow-y: auto;
  background: #FFFFFF;

  @media (min-width: 481px) and (max-width: 767px) {
    overflow-y: unset;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    overflow-y: unset;
  }

  // box-shadow: 0px 8px 28px -6px rgb(19 43 75 / 24%), 0px 18px 88px -4px rgb(19 43 75 / 28%);
}

.banner_container {
  height: 100vh;
}

.left_container {
  padding: 2% 3% 0% 3%;
}

.transparent-btn {
  border: none;
  // border-radius: 3px !important;
  background-color: $grey-50;
  outline: none;
  color: $grey-200;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.transparent-back-btn{
  border: 1px solid #1B3D6A;
  border-radius: 3px !important;
  background-color: #ffffff;
  outline: none;
  color: #1B3D6A;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.transparent-btn1 {
  border: none;
  // border-radius: 3px !important;
  background-color: $grey-50;
  outline: none;
  color: $grey-200;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  height: 48px;
  position: relative;
  top: 22px;
  float: right;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.custom_primary_btn1 {
  border: 1px solid #1B3D6A;
  // border-radius: 3px !important;
  background-color: #1B3D6A;
  outline: none;
  // padding: 0.5rem 2rem !important;
  padding: 12px 24px;
  color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  position: relative;
  top: 22px;
  float: right;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.custom-primary-btn {
  border: 1px solid #1B3D6A;
  // border-radius: 3px !important;
  background-color: #1B3D6A;
  outline: none;
  // padding: 0.5rem 2rem !important;
  padding: 12px 24px;
  color: #ffffff;
  gap: 8px;
  border-radius: 4px;
  // width: 120px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.custom-text {
  color: $grayColor;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'DIN OT';
}

.btn-align {
  text-align: end;
  margin-bottom: 30px;
}

.back-btn-align {
  text-align: start;
  margin-bottom: 30px;
}

.pass-link {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #276D98;
}

.navigate-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  border: 1.5px solid $primary-500;
  border-radius: 4px;
  background: #FFFFFF;
  position: relative;
  top: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1B3D6A;
}

.right-arrow {
  position: absolute;
  left: 73.08%;
  right: -32.17%;
  top: 21%;
  bottom: 25%;
}

.left-arrow {
  position: absolute;
  left: 10%;
  right: 22.92%;
  top: 28%;
  bottom: 25%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.sub-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $primary-500;
  font-family: 'DIN OT';
  font-style: normal;
}

.bottom_link_container {
  text-align: right;
  padding-bottom: 1%;
  padding-top: 2%;
  cursor: pointer;
}

.error-icon {
  width: 20px;
  position: absolute;
  right: 12px;
  top: 15px;
}

.error-icon-select{
  width: 18px;
  position: absolute;
    right: 33px;
    top: 16px;
}

.pass-error-icon {
  position: absolute;
  right: 40px;
  top: 13px;
}

.form-field {
  margin-bottom: 10px;
  position: relative;
}

.pr-9 {
  padding-right: 9px;
}

.pl-9 {
  padding-left: 9px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #38B615;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #fff;
  border-color: #38B615;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.round input[type="radio"] {
  visibility: hidden;
}

.round input[type="radio"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="radio"]:checked+label:after {
  opacity: 1;
}

.custom-link {
  color: #276D98;
  font-family: $mainFontstyle ;
}

.l-height {
  line-height: 24px;
}


.heading-bold {
  font-weight: bold;
}

.btn-align {
  margin-top: 3%;
}

.atlassian {
  margin-top: 5%;
}

.input-section {
  display: flex;
  flex-direction: row;
  margin-top: 13%;
  margin-bottom: 6%;
}

.align-btn {
  flex: 1;
}

.right-btn {
  text-align: right;
}

.pr-9 {
  padding-right: 9px;
}

.pl-9 {
  padding-left: 9px;
}

.footer-color {
  color: $tertiary-500;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.label-field-div {
  margin-bottom: 20px;
}

.input-label {
  margin: 10px 0;
  width: 100%;
  font-family: "DIN OT";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3B3B3B;
}

.accordion-button {
  display: flex;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1B3D6A;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 20px;
  margin-left: auto;
  content: "\f078" !important;
  font-family: "Font Awesome 5 Free" !important;
  float: right !important;
  font-weight: 900 !important;
  background: none;
  transition: transform 0.2s ease-in-out;
}


.red-alert {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  width: 100%;
  height: 88px;
  left: 64px;
  top: 112px;
  background: #E6173E;
  border: 1px solid #D11538;
  box-shadow: 0px 6px 14px -6px rgb(19 43 75 / 12%), 0px 10px 32px -4px rgb(19 43 75 / 10%);
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-hints {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 56px;
  width: 100%;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.error-header {
  height: 24px;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0;
}

.error-label {
  height: 24px;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 1;
}
/* The container */
.checkBox_container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkBox_container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBox_container input:checked ~ .checkmark {
  background-color: #1B3D6A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBox_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBox_container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: 1.5px solid #626262;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
-webkit-transform: rotate(45deg);

}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow{
  top: 0px;
  border-color: transparent transparent#999;
  transform: rotate(-135deg);
 -webkit-transform: rotate(-135deg);
  border: 1.5px solid #626262;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  margin-top: -3px !important;
  margin-right: -12px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: 0;
  border: 1px solid #A5A5A5 !important;
  box-shadow: none !important;
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 0px !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border: 2px solid #E6173E !important;
  box-shadow: none !important;
  border-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 0px;
  gap: 12px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  box-sizing: border-box;
  color: #7C7C7C !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #7C7C7C !important;
  background-color: #ebf5ff;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var($grey-900) !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var($grey-900) !important;
}

.ng-select span {
  box-sizing: border-box;
  font-family: 'DIN OT';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var($grey-900) !important;
}

.accordion-button:not(.collapsed) {
  color: var($primary-500);
  background-color: #F4F7F9;
  box-shadow: none !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important
}

.bottom-btn {
  display: flex; justify-content: space-between;
}

ul.video-detection-hints {
  list-style-type: square;
  padding-left: 16px;
}

// .ngx-ic-overlay{
//   left: 0;
//   outline: none !important;
//   pointer-events: none;
//   position: absolute;
//   top: 0;
//   touch-action: none;
// }

// img.ngx-ic-source-image{
//   visibility: visible;
//   width: 178x;
//   height: 270px;
// }

// .cropper-container1{
//   display: block; width: 100%
// }

.cropped-img-container {
  margin-bottom: 20px;
}

// .img-item {
//   width: 178px;
//   height: 270px;
// }
.custom-line-height{
  line-height: 16px !important;
}
.checkbox-label{
  font-weight: 500 !important;
  color: #38B615;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;;
}